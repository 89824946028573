body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #313743;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
* {
  scrollbar-color: gray var(--bs-primary);
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: var(--bs-light);
  width: 16px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
  border-radius: 20px;
  border: 0;
}

@media (max-width: 1399px) {
  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    background: var(--bs-light);
    width: 4px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--bs-primary);
    border-radius: 20px;
    border: 0;
  }
}

main {
  margin-top: 100px;
}

@media (max-width: 1399px) {
  main {
    margin-top: 100px;
  }
}
[aria-label=breadcrumb] {
  background-color: #ebedf5;
}
.bg-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-right: 1rem;
  background-color: #EBEDF5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
}
.card-title {
  display: flex;
  padding-left: 2rem;
  align-items: center;
}
.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-primary {
  background-color: #08338C !important;
}

.font-sarabun {
  font-family: "Sarabun", sans-serif;
}

.font-prompt {
  font-family: "Prompt", sans-serif;
}
.weight-500,
.fst-italic{
  font-weight: 500;
}

.button-to-top {
  position: absolute;
  right: 0;
  z-index: 10;
  top: 30px;
  cursor: pointer;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  background-color: var(--bs-info);
  color: #fff;
  margin-right: 0.75rem;
}
.font-weight-500{
  font-weight: 500;
}

@import url(https://fonts.googleapis.com/css2?family=Prompt&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sarabun&display=swap);
* {
  scrollbar-color: gray var(--bs-primary);
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: var(--bs-light);
  width: 16px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
  border-radius: 20px;
  border: 0;
}

@media (max-width: 1399px) {
  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    background: var(--bs-light);
    width: 4px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--bs-primary);
    border-radius: 20px;
    border: 0;
  }
}
.cookie-consent-popup.open {
  background-color: rgba(8, 51, 140, 0.75);
  padding: 12px;
}

.block-cookies .cookie-consent-popup .cookie-consent-message {
  margin: 0;
}

.cookie-consent-link {
  display: none;
}

.block-cookies {
  font-family: "Athiti";
}
.block-cookies .cookie-consent-text {
  font-size: 14px;
  float: left;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #fff;
}
.block-cookies .cookie-consent-text a.privacy-policy {
  color: #fff;
  text-decoration: underline;
}
.block-cookies .cookie-consent-top-wrapper {
  width: 1108px;
  margin: 0 auto;
}
.block-cookies .cookie-consent-controls-toggle {
  display: none !important;
}
.block-cookies .cookie-consent-details-toggle {
  display: none;
}

@media (max-width: 1200px) {
  .block-cookies .cookie-consent-top-wrapper {
    width: 928px;
    margin: 0 auto;
  }
}
@media (max-width: 992px) {
  .block-cookies .cookie-consent-top-wrapper {
    width: 100%;
    padding: 0 12px;
    margin: 0 auto;
  }
}
@media (max-width: 867px) {
  .block-cookies .cookie-consent-popup .cookie-consent-accept-all {
    float: none !important;
    margin: 0 auto;
  }
}
.block-cookies .cookie-consent-popup .cookie-consent-accept-all {
  font-family: "Athiti";
  display: block;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  width: 120px;
  padding: 10px 15px;
  line-height: 1.42857143;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  color: #FFF;
  background-color: #26B9D1;
  border: 1px solid #26B9D1;
  border-radius: 1.25rem;
  float: right;
  font-weight: 600;
  margin-top: 1rem;
}

.w-full {
  width: 100%;
}

#step {
  overflow-x: auto;
}

.steps {
  counter-reset: step;
  grid-auto-columns: 1fr;
  display: inline-grid;
  overflow: hidden;
  overflow-x: auto;
  grid-auto-flow: column;
  min-width: 600px;
}
.steps .step {
  display: grid;
  place-items: center;
  text-align: center;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-rows: 116px 1fr;
  min-width: 4rem;
  height: auto;
}
.steps .step.step-first::before {
  content: "";
  opacity: 0;
}
.steps .step.info::before {
  background-color: var(--bs-info);
}
.steps .step.yellow .bullet.active {
  background-color: var(--bs-yellow) !important;
}
.steps .step.yellow .bullet:hover {
  background-color: var(--bs-yellow) !important;
  color: var(--bs-white);
  cursor: pointer;
}
.steps .step.orange .bullet.active {
  background-color: var(--bs-orange) !important;
}
.steps .step.orange .bullet:hover {
  background-color: var(--bs-orange) !important;
  color: var(--bs-white);
  cursor: pointer;
}
.steps .step.success .bullet.active {
  background-color: var(--bs-success) !important;
}
.steps .step.success .bullet:hover {
  background-color: var(--bs-success) !important;
  color: var(--bs-white);
  cursor: pointer;
}
.steps .step .bullet {
  z-index: 0;
  border-radius: 50%;
  display: block;
  display: grid;
  place-items: center;
  place-self: center;
  position: relative;
  height: 100px;
  width: 100px;
  background-color: var(--bs-light);
  color: var(--bs-gray);
  grid-column-start: 1;
  grid-row-start: 1;
  margin-bottom: 1rem;
  text-decoration: none;
}
.steps .step .bullet.past {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}
.steps .step .bullet.active {
  background-color: var(--bs-info);
  color: var(--bs-white);
}
.steps .step::before {
  background-color: var(--bs-light);
  color: gray;
  grid-column-start: 1;
  grid-row-start: 1;
  content: "";
  height: 0.5rem;
  top: 0;
  width: 100%;
  content: "";
  margin-left: -100%;
  box-sizing: border-box;
  border: 0 solid #e4e4e7;
  grid-column-start: 1;
  grid-row-start: 1;
}

.steps .step-primary + .step-primary:before,
.steps .step-primary:after {
  background-color: gray;
  color: #000;
}

.card {
  font-family: "Prompt", sans-serif;
}

.card-title {
  display: flex;
  /* padding-left: 2rem; */
  align-items: center;
}

.title {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
}

#operational_work table tbody tr td:first-child,
#operational_work_contact table tbody tr td:first-child {
  max-width: 300px;
  width: 33%;
}
#operational_work table tbody tr td p,
#operational_work_contact table tbody tr td p {
  margin-bottom: 0;
}

.button-next-section {
  position: absolute;
  right: 0;
  z-index: 10;
  bottom: 0;
  cursor: pointer;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  background-color: var(--bs-info);
  color: #fff;
  margin-right: 0.75rem;
}

.button-to-top {
  position: absolute;
  right: 0;
  z-index: 10;
  top: 30px;
  cursor: pointer;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  background-color: var(--bs-info);
  color: #fff;
  margin-right: 0.75rem;
}

.button-to-top-show {
  visibility: visible;
}

.container-checkmark {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkmark input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container-checkmark:hover input ~ .checkmark {
  background-color: #ccc;
}

.container-checkmark input:checked ~ .checkmark {
  background-color: #26b9d1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkmark input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkmark .checkmark:after {
  left: 11px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.btn-upload-profile {
  cursor: pointer;
  width: 45px;
  height: 45px;
  bottom: 0;
  right: 38px;
  position: absolute;
  border-radius: 50%;
  background-color: #D1D0D0;
  border: solid 6px #EBEDF5;
}

.btn-success {
  color: var(--bs-white);
}
.btn-success:hover {
  color: var(--bs-white);
}
.btn-success:active {
  color: var(--bs-white);
}

.dynamicform_wrapper .fileinput-remove-button {
  border-radius: 0;
}
.dynamicform_wrapper .fileinput-cancel-button {
  border-radius: 0;
  margin-left: 1px;
}
.dynamicform_wrapper .btn-file {
  border-radius: 0;
  margin-left: -0.4rem;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;

}
.btn-file{
  padding: 0.5rem;
}
.dynamicform_wrapper .file-preview .fileinput-remove {
  border: 0px;
  background: transparent;
}

.btn-file {
  border-radius: 0 !important;
  cursor: pointer !important;
  /* border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important; */
}

.swiper-pagination-bullet {
  background-color: var(--bs-info) !important;
  width: 12px !important;
  height: 12px !important;
}

.swiper-button-next {
  background-color: #fff !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  right: 4% !important;
  left: auto;
}

.swiper-button-prev {
  background-color: #fff !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  left: 4% !important;
  right: auto;
}

.swiper-button-next:after {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900 !important;
  content: "\f105" !important;
  font-size: 32px !important;
  color: #000;
}

.swiper-button-prev:after {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900 !important;
  content: "\f104" !important;
  font-size: 32px !important;
  color: #000;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0d3996;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0d3996;
}

input:checked + .slider:before {
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.profile-picture img {
  -o-object-fit: cover;
     object-fit: cover;
  border: solid var(--bs-light) 8px;
}

.profile-thumbnail {
  -o-object-fit: cover;
     object-fit: cover;
}

.select2-container--default .select2-selection--single {
  padding: 0.825rem 1rem;
  border: 1px solid #7e89a5 !important;
  height: 54.39px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 0 !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 8px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 44px !important;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: lighter !important;
  font-size: 37px !important;
  color: var(--bs-danger) !important;
  position: relative !important;
  bottom: 2px !important;
}

.select2-selection {
  padding: 0.825rem 1rem !important;
  border: 1px solid #7e89a5 !important;
  height: 54.39px !important;
}

.select2-container--krajee-bs4 .select2-selection--single .select2-selection__arrow {
  border-left: 0 !important;
}

.has-success .select2-container--open .select2-selection,
.has-success .select2-container--krajee-bs4.select2-container--focus .select2-selection {
  box-shadow: none !important;
}

.select2-container--krajee-bs4 .select2-selection--single .select2-selection__clear,
.select2-container--krajee-bs4 .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: lighter !important;
  font-size: 37px !important;
  color: var(--bs-danger) !important;
  position: relative !important;
  bottom: 6px !important;
  opacity: 1 !important;
}

.select2-selection__rendered {
  font-size: 16px;
  color: #000;
  font-family: "Prompt", sans-serif;
}
.select2-selection__rendered .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: lighter !important;
  font-size: 37px !important;
  color: var(--bs-danger) !important;
  position: relative !important;
  bottom: 12px !important;
}

.select2-results__option {
  font-family: "Prompt", sans-serif;
}

.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
  background-color: #08338c;
  color: #fff;
  font-family: "Prompt", sans-serif;
}

input[type=text]:disabled,
.input-disabled select,
.input-disabled input
.input-disabled .react-datepicker__input-container div,
#react-select-25-placeholder{
  /* background: #ebedf5; */
    /* background: #ffffff;
    color: #cecece; */
    color: #495079;
}

select:disabled {
  background: #ebedf5;
}

.has-error .help-block {
  /* color: var(--bs-danger); */
  top: 100%;
  z-index: 5;
  display: block;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: 0.25rem;
}

.form-group.required label.control-label:after {
  content: " *";
  color: var(--bs-danger);
}

.input-group-text.kv-date-picker {
  background-color: #fff !important;
  border: solid 1px #7e89a5;
  border-left: 0px;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-radius: 0 !important;
  border-bottom-right-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}
.input-group-text.kv-date-remove {
  display: none;
}

.krajee-datepicker {
  border: solid #7e89a5 1px !important;
  border-right: 0 !important;
}
.krajee-datepicker:focus {
  box-shadow: none !important;
}

.breadcrumb-item a {
  text-decoration: none;
}

.status-pending {
  color: #c7b303;
}

.status-approved {
  color: green;
}

.status-rejected {
  color: #8d0404;
}

.pagination li > a {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #08338c;
  text-decoration: none;
}
.pagination li > :not(a) {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #08338c;
}
.pagination li.prev > :not(a), .pagination li.prev > a {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pagination li.next > :not(a), .pagination li.next > a {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/*---------------------------------------
  NAVIGATION              
-----------------------------------------*/
.navbar {
  height: 100px;
  font-family: "Prompt", sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}
.navbar .nav-item {
  height: 100px;
  display: flex;
  align-items: center;
}
.navbar .nav-item:focus, .navbar .nav-item:focus-visible, .navbar .nav-item:focus-within {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.navbar .nav-item .nav-link {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.navbar .nav-item .nav-link:focus, .navbar .nav-item .nav-link:focus-visible, .navbar .nav-item .nav-link:focus-within {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar-brand img {
  width: 300px;
}
@media (max-width: 1399px) {
  .navbar .navbar-brand img {
    width: 225px;
  }
}

/* .dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  font-size: 16px;
  margin-left: 0.75rem;
  border: 0 !important;
} */
.nav-link{
  padding: 38px 10px
}
.navbar-nav.menu .nav-link {
  position: relative;
}
.navbar-nav.menu .nav-link::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 0;
  background-color: var(--bs-info);
  left: 0;
  bottom: 0;
  transition: 0.3s;
}
.navbar-nav.menu .nav-link:hover:before {
  width: 100%;
}
.navbar-nav.menu .nav-link.show,
.navbar-nav.menu .nav-link.active {
  color: var(--bs-info) !important;
}
.navbar-nav.menu .nav-link.show::before,
.navbar-nav.menu .nav-link.active::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: var(--bs-info);
  left: 0;
  bottom: 0;
  transition: 0.3s;
}
.navbar-nav.menu .nav-link:hover {
  color: var(--bs-info) !important;
}

.dropdown-menu.show {
  margin-top: 0;
}

.img-thumbnail {
  width: 75px;
  height: 75px;
}

.btn-user-logged {
  height: 100%;
  align-items: center;
  display: flex;
}

.nav-item {
  height: 100px;
  align-items: center;
}
.nav-item .dropdown-menu {
  border: 0;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.nav-item .dropdown-menu .dropdown-item {
  padding: 1.125rem 3rem;
}
.nav-item .dropdown-menu::after {
  border-bottom: 11px solid #fff;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  top: -10px;
}
.nav-item .dropdown-menu.show {
  top: 100%;
  left: auto;
  right: 0;
  width: 300px;
}

.nav-loged {
  display: flex;
}
.navbar .nav-item #basic-nav-dropdown.nav-link{
  padding: 1.25rem 0.75rem;
}

@media (max-width: 1200px) {
  .navbar-dark .navbar-nav .nav-link {
    padding: 1rem 1rem;
    width: 100%;
  }
}

@media (max-width: 1399px) {
  /* .navbar {
    height: auto;
  } */
  .navbar .nav-item {
    height: auto;
    display: block;
    align-items: center;
  }
  /* .navbar .nav-item .nav-link {
    padding: 1rem 1rem;
    width: 100%;
  } */
  .navbar .nav-item .dropdown-menu {
    border: 0;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar .nav-item .dropdown-menu .dropdown-item {
    padding: 1rem;
  }
  .navbar .nav-item .dropdown-menu::after {
    border-bottom: 11px solid #fff;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 45px;
  }
  .navbar .nav-item .dropdown-menu.show {
    top: 100%;
    left: auto;
    right: 0;
    width: 100%;
  }
  .navbar .navbar-brand {
    height: 80px;
    display: flex;
    align-items: center;
  }
  .navbar .nav-loged .nav-link {
    padding: 0.75rem 1rem;
  }
  .navbar .nav-loged .dropdown-menu::after {
    top: 68px !important;
  }
  .navbar .nav-login {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  .navbar .nav-login .nav-link {
    width: 137px;
    margin: 0 auto;
  }

  .navbar-collapse {
    background-color: var(--bs-primary);
  }
  .navbar-collapse.collapse.show {
    overflow-y: scroll;
    max-height: calc(100vh - 60px);
  }

  .img-thumbnail {
    width: 55px;
    height: 55px;
  }
}
[aria-label=breadcrumb] {
  background-color: #ebedf5;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

/*---------------------------------------
  NAVIGATION ANIMATION              
-----------------------------------------*/
.navbar-toggler {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0 1rem 0 0;
  width: 30px;
  height: 35px;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-icon {
  background: transparent;
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-icon:before,
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon:after {
  transition: top 300ms 50ms ease, transform 300ms 350ms ease;
  top: 0;
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-icon:before {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-icon:after {
  transform: rotate(-45deg);
}

.navbar-toggler .navbar-toggler-icon {
  background: var(--bs-light);
  transition: background 10ms 300ms ease;
  display: block;
  width: 30px;
  height: 2px;
  position: relative;
}

.navbar-toggler .navbar-toggler-icon:before,
.navbar-toggler .navbar-toggler-icon:after {
  transition: top 300ms 350ms ease, transform 300ms 50ms ease;
  position: absolute;
  right: 0;
  left: 0;
  background: var(--bs-light);
  width: 30px;
  height: 2px;
  content: "";
}

.navbar-toggler .navbar-toggler-icon:before {
  top: -8px;
}

.navbar-toggler .navbar-toggler-icon:after {
  top: 8px;
}

main {
  margin-top: 100px;
}

@media (max-width: 1399px) {
  main {
    margin-top: 100px;
  }
}
.footer-content {
  background-image: url(./assets/images/BG_Footer.png);
  height: 335px;
  background-size: cover;
}

.footer-contrainer {
  background: #243e80;
  background: linear-gradient(0deg, #2d488e 0%, #2d488e 100%);
}

.cookie {
  background-color: #39559e;
}

.item-news {
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
}
.item-news:hover {
  transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
}
.item-news .desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

/* animation */
.appear {
  transition: all 0.8s;
  opacity: 0;
  transform: translateY(40px);
}

.appear.inview {
  opacity: 1;
  transform: none;
  transition-delay: 0.3s;
}

.appear2 {
  transition: all 0.8s;
  opacity: 0;
  transform: translateY(20px);
}

.appear2.inview2 {
  opacity: 1;
  transform: none;
}

.appear2.inview2:nth-child(1) {
  transition-delay: 0.1s;
}

.appear2.inview2:nth-child(2) {
  transition-delay: 0.2s;
}

.appear2.inview2:nth-child(3) {
  transition-delay: 0.3s;
}

.appear2.inview2:nth-child(4) {
  transition-delay: 0.4s;
}

.appear2.inview2:nth-child(5) {
  transition-delay: 0.5s;
}

.grid-view table thead th {
  text-align: center;
}
.grid-view table thead th a {
  color: #313743;
  text-decoration: none;
}
.grid-view table tbody tr td {
  text-align: center;
}
.grid-view table tbody tr td button {
  padding: 5px 10px;
}
.grid-view table .action-column {
  max-width: 270px;
  width: 270px;
}

.btn-add {
  width: 175px;
}

.form-control {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.form-control:hover, .form-control:focus {
  border: 1px solid #e3e3e3;
}

.form-group {
  margin-bottom: 20px;
}

.fs-sm {
  font-size: 0.875rem;
}

/*Margin and Padding helpers*/
/*xs*/
.p-xs {
  padding: 0.25rem;
}

.p-x-xs {
  padding: 0 0.25rem;
}

.p-y-xs {
  padding: 0.25rem 0;
}

.p-t-xs {
  padding-top: 0.25rem;
}

.p-r-xs {
  padding-right: 0.25rem;
}

.p-b-xs {
  padding-bottom: 0.25rem;
}

.p-l-xs {
  padding-left: 0.25rem;
}

.m-xs {
  margin: 0.25rem;
}

.m-x-xs {
  margin: 0 0.25rem;
}

.m-y-xs {
  margin: 0.25rem 0;
}

.m-r-xs {
  margin-right: 0.25rem;
}

.m-l-xs {
  margin-left: 0.25rem;
}

.m-t-xs {
  margin-top: 0.25rem;
}

.m-b-xs {
  margin-bottom: 0.25rem;
}

/*sm*/
@media (min-width: 768px) {
  /*sm*/
  .p-sm {
    padding: 0.5rem;
  }

  .p-x-sm {
    padding: 0 0.5rem;
  }

  .p-y-sm {
    padding: 0.5rem 0;
  }

  .p-t-sm {
    padding-top: 0.5rem;
  }

  .p-r-sm {
    padding-right: 0.5rem;
  }

  .p-b-sm {
    padding-bottom: 0.5rem;
  }

  .p-l-sm {
    padding-left: 0.5rem;
  }

  .m-sm {
    margin: 0.5rem;
  }

  .m-x-sm {
    margin: 0 0.5rem;
  }

  .m-y-sm {
    margin: 0.5rem 0;
  }

  .m-t-sm {
    margin-top: 0.5rem;
  }

  .m-r-sm {
    margin-right: 0.5rem;
  }

  .m-b-sm {
    margin-bottom: 0.5rem;
  }

  .m-l-sm {
    margin-left: 0.5rem;
  }
}
/*md*/
@media (min-width: 992px) {
  .p-md {
    padding: 1rem;
  }

  .p-x-md {
    padding: 0 1rem;
  }

  .p-y-md {
    padding: 1rem 0;
  }

  .p-t-md {
    padding-top: 1rem;
  }

  .p-r-md {
    padding-right: 1rem;
  }

  .p-b-md {
    padding-bottom: 1rem;
  }

  .p-l-md {
    padding-left: 1rem;
  }

  .m-md {
    margin: 1rem;
  }

  .m-x-md {
    margin: 0 1rem;
  }

  .m-y-md {
    margin: 1rem 0;
  }

  .m-t-md {
    margin-top: 1rem;
  }

  .m-r-md {
    margin-right: 1rem;
  }

  .m-b-md {
    margin-bottom: 1rem;
  }

  .m-l-md {
    margin-left: 1rem;
  }
}
/*lg*/
@media (min-width: 1200px) {
  .p-lg {
    padding: 1.5rem;
  }

  .p-x-lg {
    padding: 0 1.5rem;
  }

  .p-y-lg {
    padding: 1.5rem 0;
  }

  .p-t-lg {
    padding-top: 1.5rem;
  }

  .p-r-lg {
    padding-right: 1.5rem;
  }

  .p-b-lg {
    padding-bottom: 1.5rem;
  }

  .p-l-lg {
    padding-left: 1.5rem;
  }

  .m-lg {
    margin: 1.5rem;
  }

  .m-x-lg {
    margin: 0 1.5rem;
  }

  .m-y-lg {
    margin: 1.5rem 0;
  }

  .m-t-lg {
    margin-top: 1.5rem;
  }

  .m-r-lg {
    margin-right: 1.5rem;
  }

  .m-b-lg {
    margin-bottom: 1.5rem;
  }

  .m-l-lg {
    margin-left: 1.5rem;
  }
}
/*xl*/
.p-xl {
  padding: 3rem;
}

.p-x-xl {
  padding: 0 3rem;
}

.p-y-xl {
  padding: 3rem 0;
}

.p-t-xl {
  padding-top: 3rem;
}

.p-r-xl {
  padding-right: 3rem;
}

.p-b-xl {
  padding-bottom: 3rem;
}

.p-l-xl {
  padding-left: 3rem;
}

.m-xl {
  margin: 3rem;
}

.m-x-xl {
  margin: 0 3rem;
}

.m-y-xl {
  margin: 3rem 0;
}

.m-t-xl {
  margin-top: 3rem;
}

.m-r-xl {
  margin-right: 3rem;
}

.m-b-xl {
  margin-bottom: 3rem;
}

.m-l-xl {
  margin-left: 3rem;
}

.top--4 {
  top: -4px !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.font-prompt {
  font-family: "Prompt", sans-serif;
}

.font-kanit {
  font-family: "Kanit", sans-serif;
}

.font-sarabun {
  font-family: "Sarabun", sans-serif;
}

.bg-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-right: 1rem;
  background-color: #EBEDF5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification::after {
  content: "";
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-28 {
  font-size: 28px !important;
}

.mw-800 {
  max-width: 800px;
}

.w-800 {
  width: 800px;
}

.help-block {
  color: var(--bs-danger);
}

.flag {
  height: 30px;
  margin-right: 10px;
}

.scroll-container:nth-of-type(even) {
  flex-direction: row-reverse;
}

@media screen and (max-width: 650px) {
  .scroll-container,
.scroll-container:nth-of-type(even) {
    flex-direction: column;
    align-content: inherit;
  }

  .scroll-element {
    height: 100%;
  }

  .scroll-element,
.scroll-caption {
    width: 100%;
  }
}
/**Styling scrollable elements*/
.js-scroll {
  opacity: 0;
  transition: opacity 500ms;
}

.js-scroll.scrolled {
  opacity: 1;
}

.scrolled.fade-in {
  -webkit-animation: fade-in 1s ease-in-out both;
          animation: fade-in 1s ease-in-out both;
}

.scrolled.fade-in-bottom {
  -webkit-animation: fade-in-bottom 1s ease-in-out both;
          animation: fade-in-bottom 1s ease-in-out both;
}

.scrolled.slide-left {
  -webkit-animation: slide-in-left 1s ease-in-out both;
          animation: slide-in-left 1s ease-in-out both;
}

.scrolled.slide-right {
  -webkit-animation: slide-in-right 1s ease-in-out both;
          animation: slide-in-right 1s ease-in-out both;
}

/* ----------------------------------------------
     * Generated by Animista on 2021-2-11 23:32:31
     * Licensed under FreeBSD License.
     * See http://animista.net/license for more info. 
     * w: http://animista.net, t: @cssanimista
     * ---------------------------------------------- */
@-webkit-keyframes slide-in-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card-body {
  min-height: 500px;
}

.site-dashboard .card-body{
  min-height: 225px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background-color: transparent;
}

#profile-years_type input{
  margin: 0.5rem
}

.select-form-control > div > div{
  border-color: #7e89a5!important;
  border: 2px solid #dee2e6!important;
  border: 2px solid #7e89a5!important;
  border-radius: 0.3rem;
  font-size: 1.25rem;
  font-size: 16px!important;
  min-height: calc(1.5em + 1.65rem + 4px);
  /* padding: 0.825rem 1rem; */
  width: 100%;
}
.file-caption .input-group-btn.input-group-append{
  border: 1px solid #7E89A5;
}
.file-caption .input-group-btn button{
  border-radius: 0 !important;
  border: 0;
  cursor: pointer !important;
}
.file-caption .input-group-btn input[type="file"]{
display: none;
}
.btn-upload{
  line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    box-shadow: 0 2px #00000004;
    cursor: pointer;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    font-size: 14px;
    color: #000000d9;
    background: #fff;
    padding: 0.5rem 0.75rem;
    height: auto;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
}
.btn-upload>.icon-upload+span, .btn-upload>span+.icon-upload {
  margin-left: 8px;
}
.btn-input-upload{ 
  position: absolute;
  width: 110px;
  height: 32px;
}
.upload-list{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  line-height: 1.5715;
}
.upload-list:before {
  display: table;
  content: "";
}
.upload-list .upload-list-container{
  transition: opacity .3s,height .3s;
}
.upload-list .upload-list-container .upload-list-item{
  position: relative;
  /* height: 45px; */
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 8px;
  font-size: 14px;
} 
.upload-list .upload-list-item .upload-list-item-description{
  margin-top: 10px;
}
.upload-list .upload-list-item .upload-list-item-description textarea{
  border: 1px solid #d9d9d9;
  border-radius: 0;
}
.upload-list .upload-list-item .upload-list-item-description .input-group-text{
  border: 1px solid #d9d9d9;
    background-color: #ebedf5;
    font-size: 14px;
}
.upload-list .upload-list-item .upload-list-item-info .upload-span{
  display: flex;
  align-items: center;
}
.upload-list .upload-list-item .upload-list-item-info .upload-list-item-thumbnail{
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.upload-list .upload-list-item .upload-list-item-info .upload-list-item-name{
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.upload-list .upload-list-item .upload-list-item-info .upload-list-item-card-actions{
  position: static;
  flex: none;
  top: 22px;
  right: 0;
  line-height: 0;
}
.upload-list .upload-list-item .upload-list-item-card-actions .btn-item-card-actions-remove{
    opacity: 1;
    
    line-height: 1;
    width: 24px;
    height: 24px;
    padding: 0;
    color: #000000d9;
    border-color: transparent;
    background: 0 0;
    box-shadow: none;
}
.item-expense{
  border: 1px solid rgb(210, 210, 210);
  padding: 2rem 1rem 1rem 1rem;
  display: flex;
  flex-wrap: wrap;
}

.item-expense .remove{
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}

.item-remove{
  position: absolute;
  right: 40px;
  margin-top: -20px;
}
.item-phone-number{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.item-personal .remove{
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}
.item-email{
  width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}
.btn-download{
  color:#fff;
}
.btn-view-detail{
  width: 180px;
}
.grid-view table tbody tr td.file{
  max-width: 150px;
  width: 150px;
}
.ReactModal__Content{
  max-height: 100%;
  max-width: 100%;
  font-family: "Sarabun", sans-serif;
}
.ReactModalPortal{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.nav-tabs .nav-link {
  background-color: rgb(255, 255, 255);
  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  margin-bottom: 0px;
  border-bottom: 4px solid transparent;
  padding: 0 10px
}
.nav-tabs .nav-item{
  height: 50px;
  padding: 0 0.5rem;
}
.site-dashboard .card-title{
  padding-left: 0;
}
.card-personal-information .nav-tabs{
  border-bottom: 0;
  padding-left: 1rem;
  height: 50px;
  border-bottom: 2px solid rgba(0,0,0,.06);
}
.card-personal-information .tab-content .card .card-body{
  padding: 1rem 0;
}
.card-personal-information .tab-content .card .card-title{
  /* padding-left: 0;
  font-weight: 500; */
  display: none;
}
.card-personal-information .tab-content .card {
  border: 0;
  border-radius: 0;
}
.card-personal-information .nav-tabs .nav-link.active, 
.nav-tabs .nav-item.show .nav-link {
  color: #08338C ;
  background-color: #fff;
  border-color: #08338C  #08338C  #fff;
  border-bottom: 3px solid;
  height: 50px;
}

.card-personal-information .nav-tabs .nav-link{
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  height: 48px;
}
.card-personal-information  .nav-tabs .nav-link {
  background-color: rgb(255, 255, 255);
  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  margin-bottom: 0px;
  border-bottom: 3px solid transparent;
  padding: 0 10px;
}

.account-page{
  font-family: "Prompt", sans-serif;
  align-items: center;
  display: flex;
  justify-content: center;
}
.account-page .main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

body{
  background-color: #f7f7f7;
  color: #1f1f1f;
  font-size: 1rem;
  min-height: 100%;
  overflow-x: hidden;
}
.account-logo {
  margin-bottom: 30px;
  text-align: center;
}
.account-page .account-content{
  padding: 3rem 0;
}
.account-page.forget-password-page .account-content{
  padding: 3rem 0 6rem 0;
}
.btn-close{
  float: right;
}
.forget-password-page .account-box {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
  margin: 0 auto;
  overflow: hidden;
  width: 480px;
}
.forget-password-page .account-wrapper {
  padding: 30px;
}
.forget-password-page .account-title {
  font-family:  "Prompt", sans-serif;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}
.forget-password-page .account-subtitle {
  color: #4c4c4c;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
}
.forget-password-page  .account-box label {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;

}
.forget-password-page  .account-box .account-btn {
  background: linear-gradient(90deg,#3050bb 0,#1a2981);
  border: 0;
  border-radius: 4px;
  display: block;
  font-family: Athiti;
  font-size: 22px;
  padding: 10px 26px;
  width: 100%;
  font-family:  "Prompt", sans-serif;
}

.help-block {
  color: red;
  font-size: 14px;
  text-align: left;
  padding: 5px 0;
  font-weight: normal;
}
.help-block:before {
  content: "⚠ ";
  display: inline;
}

.label-with-required:after {
  color: red;
  content: " *";
}
.account-page h4{
  font-size: 1.25rem;
}
.card-expense{
  border: 1px dashed rgb(227, 227, 227);
  border-radius: 0;
  background-color: rgb(254, 253, 255);
}
.card-expense .table  tr > td{
  border: 0;
  padding: 15px;

}
.btn-edit {
  background: #ffbc34;
  border: 1px solid #ffbc34;
}
/* footer.footer{
  position: fixed;
  bottom: 0;
  width: 100%;
} */
.page-item.active .page-link {
  background-color: #08338C;
  border-color: #08338C;
}
.page-item.active .page-link {
  background-color: #08338C;
  border-color: #08338C;
  color: #fff;
  z-index: 3;
}
.page-item:first-child .page-link {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  margin-left: 0;
}
.page-item .page-link {
  padding: 0.5rem 1rem;
}
.btn-file-upload{
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none; 
  padding: 0.5rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 2px #00000004;
  color: #000000d9;
  background: #fff;
  border: 1px solid #e3e3e3;
}

.btn-file-upload input[type="file"] {
  position: absolute;
  left: 55px;
  width: 140px;
  height: 43px;
  margin-top: -10px;
  /* margin-left: -30px; */
  padding: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
}

.portfolio-form .btn-file-upload input[type="file"]{
   left: 18.5%;
}

@media screen and (max-width: 767px) {
  .portfolio-form .btn-file-upload input[type="file"] {
    left: 30px;
  }
}
main{
  min-height: 750px;
}
.btn-show-password{
  border-radius: 0;
  border: 0; 
  /* border: 1px solid #e3e3e3; */
}
.input-password{
  border: 0;
}
.input-password:hover,
.input-password:focus{ 
  border: 0;
}
.pagination{
  margin-top: 1rem;
}
.fs-14{
  font-size: 14px;
}
.block-step-description{
  max-width: 720px;
   margin: 0 auto;
  overflow-x: auto;
}
.site-dashboard .steps .step .bullet{
  cursor: unset;
}
.togglePasswordType{
  position: absolute;
    font-size: 18px;
    z-index: 10;
    right: 15px;
    top: 12px;
    cursor: pointer;
}
.reset-password .form-group input{
  width: calc(100% - 30px);
}
.reset-password .form-group label{
  width: 100%;
}
.reset-password .form-group .togglePasswordType {
  position: relative;
  font-size: 18px;
  z-index: 10;
  right: 30px;
  top: 12px;
  cursor: pointer;
}

.btn-cal-duration{
  font-size: 0.9rem;
  padding: 0px 9px;
  color: #fff;
  background-color: #ff9400;
  border-color: #ff9400;
}
.btn-cal-duration:hover,
.btn-cal-duration:focus
{
   border-color: #ff9400;
  background-color: #ff9d17;
  color: #fff;
}
.swal2-container.swal2-center>.swal2-popup{
  font-family: "Prompt", sans-serif;
}
.modal {
  font-family: "Prompt", sans-serif;
}
.modal .modal-dialog .table-bordered > :not(caption) > * {
  border-width: 1px;
}
.modal  .modal-header{
  border-bottom: 0;
}
.modal  .modal-title{
  width: 100%;
  text-align: center;

}
.img-alert-warning{
  width: 35%;
  margin: 2rem auto;
}


.input-button{
  .form-control{
    width: 40%;
    float: left;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn{
    width: 60%;
    float: left;
    height: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}