@font-face {
    font-family: 'Prompt';
    src: url('Prompt-Black.eot');
    src: url('Prompt-Black.eot?#iefix') format('embedded-opentype'),
        url('Prompt-Black.woff2') format('woff2'),
        url('Prompt-Black.woff') format('woff'),
        url('Prompt-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-BlackItalic.eot');
    src: url('Prompt-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Prompt-BlackItalic.woff2') format('woff2'),
        url('Prompt-BlackItalic.woff') format('woff'),
        url('Prompt-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-Bold.eot');
    src: url('Prompt-Bold.eot?#iefix') format('embedded-opentype'),
        url('Prompt-Bold.woff2') format('woff2'),
        url('Prompt-Bold.woff') format('woff'),
        url('Prompt-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-BoldItalic.eot');
    src: url('Prompt-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Prompt-BoldItalic.woff2') format('woff2'),
        url('Prompt-BoldItalic.woff') format('woff'),
        url('Prompt-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-ExtraBold.eot');
    src: url('Prompt-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Prompt-ExtraBold.woff2') format('woff2'),
        url('Prompt-ExtraBold.woff') format('woff'),
        url('Prompt-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-ExtraBoldItalic.eot');
    src: url('Prompt-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Prompt-ExtraBoldItalic.woff2') format('woff2'),
        url('Prompt-ExtraBoldItalic.woff') format('woff'),
        url('Prompt-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-ExtraLight.eot');
    src: url('Prompt-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Prompt-ExtraLight.woff2') format('woff2'),
        url('Prompt-ExtraLight.woff') format('woff'),
        url('Prompt-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-ExtraLightItalic.eot');
    src: url('Prompt-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Prompt-ExtraLightItalic.woff2') format('woff2'),
        url('Prompt-ExtraLightItalic.woff') format('woff'),
        url('Prompt-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-Italic.eot');
    src: url('Prompt-Italic.eot?#iefix') format('embedded-opentype'),
        url('Prompt-Italic.woff2') format('woff2'),
        url('Prompt-Italic.woff') format('woff'),
        url('Prompt-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-Light.eot');
    src: url('Prompt-Light.eot?#iefix') format('embedded-opentype'),
        url('Prompt-Light.woff2') format('woff2'),
        url('Prompt-Light.woff') format('woff'),
        url('Prompt-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-LightItalic.eot');
    src: url('Prompt-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Prompt-LightItalic.woff2') format('woff2'),
        url('Prompt-LightItalic.woff') format('woff'),
        url('Prompt-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-Medium.eot');
    src: url('Prompt-Medium.eot?#iefix') format('embedded-opentype'),
        url('Prompt-Medium.woff2') format('woff2'),
        url('Prompt-Medium.woff') format('woff'),
        url('Prompt-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-MediumItalic.eot');
    src: url('Prompt-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Prompt-MediumItalic.woff2') format('woff2'),
        url('Prompt-MediumItalic.woff') format('woff'),
        url('Prompt-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-Regular.eot');
    src: url('Prompt-Regular.eot?#iefix') format('embedded-opentype'),
        url('Prompt-Regular.woff2') format('woff2'),
        url('Prompt-Regular.woff') format('woff'),
        url('Prompt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-SemiBold.eot');
    src: url('Prompt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Prompt-SemiBold.woff2') format('woff2'),
        url('Prompt-SemiBold.woff') format('woff'),
        url('Prompt-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-SemiBoldItalic.eot');
    src: url('Prompt-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Prompt-SemiBoldItalic.woff2') format('woff2'),
        url('Prompt-SemiBoldItalic.woff') format('woff'),
        url('Prompt-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-Thin.eot');
    src: url('Prompt-Thin.eot?#iefix') format('embedded-opentype'),
        url('Prompt-Thin.woff2') format('woff2'),
        url('Prompt-Thin.woff') format('woff'),
        url('Prompt-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('Prompt-ThinItalic.eot');
    src: url('Prompt-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Prompt-ThinItalic.woff2') format('woff2'),
        url('Prompt-ThinItalic.woff') format('woff'),
        url('Prompt-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-Bold.eot');
    src: url('Sarabun-Bold.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-Bold.woff2') format('woff2'),
        url('Sarabun-Bold.woff') format('woff'),
        url('Sarabun-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-BoldItalic.eot');
    src: url('Sarabun-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-BoldItalic.woff2') format('woff2'),
        url('Sarabun-BoldItalic.woff') format('woff'),
        url('Sarabun-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-ExtraBold.eot');
    src: url('Sarabun-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-ExtraBold.woff2') format('woff2'),
        url('Sarabun-ExtraBold.woff') format('woff'),
        url('Sarabun-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-ExtraBoldItalic.eot');
    src: url('Sarabun-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-ExtraBoldItalic.woff2') format('woff2'),
        url('Sarabun-ExtraBoldItalic.woff') format('woff'),
        url('Sarabun-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-ExtraLight.eot');
    src: url('Sarabun-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-ExtraLight.woff2') format('woff2'),
        url('Sarabun-ExtraLight.woff') format('woff'),
        url('Sarabun-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-ExtraLightItalic.eot');
    src: url('Sarabun-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-ExtraLightItalic.woff2') format('woff2'),
        url('Sarabun-ExtraLightItalic.woff') format('woff'),
        url('Sarabun-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-Italic.eot');
    src: url('Sarabun-Italic.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-Italic.woff2') format('woff2'),
        url('Sarabun-Italic.woff') format('woff'),
        url('Sarabun-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-Light.eot');
    src: url('Sarabun-Light.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-Light.woff2') format('woff2'),
        url('Sarabun-Light.woff') format('woff'),
        url('Sarabun-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-LightItalic.eot');
    src: url('Sarabun-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-LightItalic.woff2') format('woff2'),
        url('Sarabun-LightItalic.woff') format('woff'),
        url('Sarabun-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-Medium.eot');
    src: url('Sarabun-Medium.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-Medium.woff2') format('woff2'),
        url('Sarabun-Medium.woff') format('woff'),
        url('Sarabun-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-MediumItalic.eot');
    src: url('Sarabun-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-MediumItalic.woff2') format('woff2'),
        url('Sarabun-MediumItalic.woff') format('woff'),
        url('Sarabun-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun';
    src: url('Sarabun-Regular.eot');
    src: url('Sarabun-Regular.eot?#iefix') format('embedded-opentype'),
        url('Sarabun-Regular.woff2') format('woff2'),
        url('Sarabun-Regular.woff') format('woff'),
        url('Sarabun-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

