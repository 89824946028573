#step {
  overflow-x: auto;
}
.font-28 {
  font-size: 28px !important;
}
.steps {
  counter-reset: step;
  grid-auto-columns: 1fr;
  display: inline-grid;
  overflow: hidden;
  overflow-x: auto;
  grid-auto-flow: column;
  min-width: 600px;
}
.steps .step {
  display: grid;
  place-items: center;
  text-align: center;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-rows: 116px 1fr;
  min-width: 4rem;
}
.steps .step.step-first::before {
  content: "";
  opacity: 0;
}
.steps .step.info::before {
  background-color: var(--bs-info);
}
.steps .step.yellow .bullet.active {
  background-color: var(--bs-yellow) !important;
}
.steps .step.yellow .bullet:hover {
  background-color: var(--bs-yellow) !important;
  color: var(--bs-white);
  cursor: pointer;
}
.steps .step.orange .bullet.active {
  background-color: var(--bs-orange) !important;
}
.steps .step.orange .bullet:hover {
  background-color: var(--bs-orange) !important;
  color: var(--bs-white);
  cursor: pointer;
}
.steps .step.success .bullet.active {
  background-color: var(--bs-success) !important;
}
.steps .step.success .bullet:hover {
  background-color: var(--bs-success) !important;
  color: var(--bs-white);
  cursor: pointer;
}
.steps .step .bullet {
  z-index: 0;
  border-radius: 50%;
  display: block;
  display: grid;
  place-items: center;
  place-self: center;
  position: relative;
  height: 100px;
  width: 100px;
  background-color: var(--bs-light);
  color: var(--bs-gray);
  grid-column-start: 1;
  grid-row-start: 1;
  margin-bottom: 1rem;
  text-decoration: none;
}
.steps .step .bullet.past {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}
.steps .step .bullet.active {
  background-color: var(--bs-info);
  color: var(--bs-white);
}
.steps .step::before {
  background-color: var(--bs-light);
  color: gray;
  grid-column-start: 1;
  grid-row-start: 1;
  content: "";
  height: 0.5rem;
  top: 0;
  width: 100%;
  content: "";
  margin-left: -100%;
  box-sizing: border-box;
  border: 0 solid #e4e4e7;
  grid-column-start: 1;
  grid-row-start: 1;
}

.steps .step-primary + .step-primary:before,
.steps .step-primary:after {
  background-color: gray;
  color: #000;
}
